
@import './node_modules/@splidejs/splide/dist/css/splide-core.min.css';

body{
	--color-main: #FFFF00;
}

*{
	margin: 0;
	padding: 0;

	color: var(--color-main);
}

svg{
	fill: var(--color-main);

	path{
		fill: var(--color-main);
	}
}


.container{
	width: 100vw;
	height: 100vh;
	min-height: -webkit-fill-available;
	height: -webkit-fill-available;
	background-color: #000;
}

.video-wrapper{
	position: fixed;
	width: 100vw;
	height: 100vh;
	height: -webkit-fill-available;

	video{
		width: 100%;
		// height: -webkit-fill-available;
		height: 100%;
		object-fit: cover;
	}
}

.logos{
	margin: 0;
	padding: 0;

	li{
		margin: 0;
		padding: 0;
		list-style: none;
		width: inherit;
		display: none;

		max-width: 206px;


		&.show{
			display: block;
		}

		svg{
			height: auto;
			width: 100%;
		}
	}
}

.mute{
	svg{
		display: none;
		cursor: pointer;

		&.off{
			display: block;
		}
	}

	&.sound-on{
		svg{
			display: none;

			&.on{
				display: block;
			}
		}
	}
}

address{
	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	font-style: normal;
	font-family: Times, serif;



	a{
		color: inherit;
		text-decoration: none;

		display: block;
	}
}

main.main{
	width: 100vw;
	box-sizing: border-box;

	position: fixed;
	left: 0;
	bottom: 0;
	top: 0;
	right: 0;



	padding: 20px 0;
	height: 100%;


	.inner-main{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;


		height: 100%;


		address{
			// order: 1;
			align-self: start;
			width: 50%;

			padding-left: 20px;
		}

		.mute{
			// order: 2;
			width: 28px;
			align-self: start;

			padding-right: 20px;
		}

		.logos{
			width: 100vw;
			margin-inline: auto;
			padding: 15%;

			li{

				margin-inline: auto;
				text-align: center;
			}
		}

		.text{

			align-self: end;

			svg{
				height: 60px;
			}

			.splide__slide{
				padding-left: 100vw;
				box-sizing: content-box;
			}
		}


		// @media only screen and (min-width: 960px) {

		// 	display: flex;
		// 	flex-wrap: wrap;
		// 	align-items: center;
		// 	justify-content: space-between;


		// 	gap: 0px;

		// 	.logos{
		// 		max-width: 210px;

		// 		li{
		// 			// max-width: 230px;
		// 		}
		// 	}

		// 	.text{
		// 		width: calc(100vw - 210px - 28px - 115px - (3 * 20px));

		// 		.splide__slide{

		// 			width: 35vw;
		// 			padding-right: 100%;
		// 			box-sizing: content-box;
		// 		}
		// 	}

		// 	address{
		// 		margin-right: 20px;
		// 		margin-bottom: 20px;
		// 	}

		// 	.mute{
		// 		width: 28px;

		// 		justify-self: end;
		// 	}
		// }

		// @media only screen and (min-width: 1280px) {
		// 	.logos{
		// 		// width: 50vw;
		// 	}
		// }

	}
}
